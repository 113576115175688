var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"BoxHeight bbox plr13 pt60 pb30 positionr"},[(_vm.machineTypeList.length == 0)?_c('div',{staticClass:"kong positiona"},[_c('img',{attrs:{"src":require("../assets/images/kong.png"),"alt":"","width":"150"}}),_c('div',{staticClass:"f15 textc mt15 text999"},[_vm._v(_vm._s(_vm.$t("my.noMessage")))])]):_vm._e(),_c('van-collapse',{model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.machineTypeList),function(item,i){return _c('van-collapse-item',{key:item.id,attrs:{"title":item.typeName,"name":i}},[_c('div',{staticClass:"subNode text111 pb15"},_vm._l((item.son),function(itemNode){return _c('div',{key:itemNode.id,staticClass:"item mt10 f14"},[_c('div',{staticClass:"subNode_tit",on:{"click":function($event){return _vm.showTypeData(
                  itemNode.typeCode,
                  itemNode.typeName,
                  itemNode.parentCode
                )}}},[_vm._v(" "+_vm._s(itemNode.typeName)+" ")]),(itemNode.son.length > 0)?_c('div',{staticClass:"content bbox secNode mt10"},_vm._l((itemNode.son),function(itemFinalNode){return _c('div',{key:itemFinalNode.id,on:{"click":function($event){return _vm.showTypeData(
                    itemFinalNode.typeCode,
                    itemFinalNode.typeName,
                    itemFinalNode.parentCode
                  )}}},[_vm._v(" "+_vm._s(itemFinalNode.typeName)+" ")])}),0):_vm._e()])}),0)])}),1)],1),_c('van-dialog',{attrs:{"title":"图片展示","show-cancel-button":false,"show-confirm-button":false,"closeOnClickOverlay":true},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"content bbox f13 text333 mt10 flex align-center justify-center"},[_c('van-uploader',{attrs:{"deletable":false,"max-count":1},model:{value:(_vm.fileUrls),callback:function ($$v) {_vm.fileUrls=$$v},expression:"fileUrls"}})],1),_c('div',{staticClass:"btns mt15 mb10"},[_c('div',{staticClass:"textc"},[_c('el-button',{staticClass:"wp80 f16 Btn",attrs:{"type":"primary"},on:{"click":_vm.gotoExamination}},[_vm._v(_vm._s(_vm.$t("closed")))])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }